/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useField } from "formik";

import FormikCheckbox from "../../../../../base/components/FormikCheckbox";
import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import {
  durationAuction,
  durationFixed,
  pricingFormat,
  pricingFormatStatuses,
} from "../../../../../base/constants/draft";
import FormikGroupAddon from "../../GroupAddon";
import { usePriceValidation } from "../../../../../base/hooks/usePriceValidation";
import {
  BUY_IT_NOW_PRICE_MIN_NUMBER,
  PRICE_STEP,
} from "../../../../../base/constants/forms";
import { StoredField } from "../../../../../base/components/StoredField";

const PriceDetails = ({ values, setFieldValue, isPosted = false }) => {
  const [, , { setTouched }] = useField({ name: "startingPrice" });
  const isAuction = values.pricingFormat === pricingFormat[0].id;

  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  useEffect(() => {
    if (!isAuction) {
      setFieldValue("startingPrice", "");
      setTouched(false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuction, setFieldValue]);

  const duration = values.duration;

  useEffect(() => {
    const list = isAuction ? durationAuction : durationFixed;

    if (!values.pricingFormat) return;

    // Auto-select
    if (list.length === 1) {
      setFieldValue("duration", list[0].id);
      // reset value
    } else if (duration && !list.find(({ id }) => id === duration)) {
      setFieldValue("duration", list[0].id);
    }
  }, [duration, setFieldValue, isAuction, values.pricingFormat]);

  return (
    <>
      <Row className="mb-3">
        <Col md="6">
          <FormikSingleSelect
            name="duration"
            label="Duration*"
            options={isAuction ? durationAuction : durationFixed}
            containerClassName="mb-0"
            disabled={
              values.pricingFormat === pricingFormatStatuses.fixedPrice ||
              isPosted
            }
          />
          {!isAuction && (
            <span className="font-size-10 text-gray-gomi mb-1">
              Listings renew automatically every 30 days, based on the listing
              terms at that time, until all quantities sell or the listing ends.
              Each time a listing renews and when an item sells, you’ll be
              charged applicable fees.
            </span>
          )}
        </Col>
      </Row>
      {isAuction && (
        <Row className="mb-3">
          <Col md="6">
            <FormikGroupAddon
              label="Starting price*"
              name="startingPrice"
              groupText="$"
              inputClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              placeholder={"00.00"}
              step={PRICE_STEP}
            />
            <span className="font-size-10 text-gray-gomi mb-1">
              To attract buyers and increase competition for your item, consider
              a low starting bid.
            </span>
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col md="6">
          <FormikGroupAddon
            label="Buy it now price*"
            name="buyItNowPrice"
            groupText="$"
            inputClassName="priceInputWrapper"
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            placeholder={"00.00"}
            step={PRICE_STEP}
            min={BUY_IT_NOW_PRICE_MIN_NUMBER}
          />
        </Col>
      </Row>

      <Row>
        <Col md="6" className="d-flex align-items-center">
          <div className="checkbox-container-upc">
            <StoredField fieldKey="allowBestOffer" />
            <FormikCheckbox
              name="allowBestOffer"
              id="allowBestOffer"
              text="Allow best offer"
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="6">
          <span className="font-size-13 text-gray-gomi mb-1">
            Let buyers make offers. Allowing offers increases your chance of
            selling by 3-7% based on recent individual seller listing
            performance.
          </span>
        </Col>
      </Row>

      {values.allowBestOffer && (
        <Row className="mb-5">
          <Col md="3">
            <FormikGroupAddon
              label="Accept offers of at least"
              name="acceptOffersOfAtLeast"
              groupText="$"
              inputClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              placeholder={"00.00"}
            />
          </Col>
          <Col md="3">
            <FormikGroupAddon
              label="Decline offer lower than"
              name="declineOfferLowerThan"
              groupText="$"
              inputClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
              placeholder={"00.00"}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default PriceDetails;
