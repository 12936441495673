/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect } from "react";
import Bugsnag from "@bugsnag/js";

import TableSearch from "../../../drafts/components/Search";
import CountEntries from "../../../drafts/components/CountEntries";
import AddDraftButton from "../AddDraftButton";
import ImportInventory from "../ImportInventory";
import StorageService from "../../../../services/StorageService";
import { useService } from "../../../../base/hooks/useService";
import { useStatelessProvider } from "../../../../base/hooks/useProvider";
import { TableContext } from "../../../drafts/contexts/table";
import { KEY_USER } from "../../../../base/constants/storage";
import { isDisabledDraft } from "../../../drafts/helpers/isDisabledDraft";
import "./index.scss";
import {
  AVAILABLE_LISTING,
  MarketplacesFilterOptions,
} from "../../../drafts/constants";
import { useTotalProducts } from "../../../drafts/hooks/useProduct";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import MergeModal from "../../../../base/components/MergeModal";
import { useModal } from "../../../../base/hooks/useModal";
import FilterInventory from "../FilterInventory";
import DraftsService from "../../../../services/DraftsService";
import { ACTIVE_INVENTORY_LABEL } from "../../constants";

const HeaderComponent = ({ isActiveSearch, toolkitProps }) => {
  const [totalInventory, setTotalInventory] = useState(0);
  const [{ active }] = useStatelessProvider(TableContext);
  const [{ total }] = useTotalProducts(PRODUCT_TYPE.DRAFT);

  const draftService = useService(DraftsService);

  const storage = useService(StorageService);
  const { activeSubscription } = storage.get(KEY_USER, {});

  const { open, close, isOpen } = useModal();

  const isDisabled = useMemo(() => {
    if (!activeSubscription) return false;
    const { type } = activeSubscription;
    return isDisabledDraft(type, total, "inventory");
  }, [total, activeSubscription]);

  const { type = null } = activeSubscription;
  useEffect(() => {
    draftService
      .getListDrafts({ limit: 1, status: PRODUCT_TYPE.ACTIVE })
      .then(({ pagination }) => {
        setTotalInventory(pagination.totalCount);
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, active]);

  return (
    <div className={"d-flex inventory-list-toolbar"}>
      <div className={"d-flex navigate-inventory-container"}>
        <TableSearch
          {...toolkitProps.searchProps}
          isActiveSearch={isActiveSearch}
          placeholder="Search by SKU or Title"
        />
        <div>
          <FilterInventory
            className="ml-3"
            withAllOption
            filterName="marketplaceType"
            filterOptions={MarketplacesFilterOptions}
          />
        </div>
      </div>

      <div className={"inventory-header-counters"}>
        <CountEntries count={totalInventory} color={"green"}>
          Active
        </CountEntries>
        {!!AVAILABLE_LISTING[type] && (
          <CountEntries
            className="mr-3"
            count={AVAILABLE_LISTING[type] - totalInventory}
            color="brown"
          >
            Available
          </CountEntries>
        )}
        <button onClick={open} className="merge-button outline-primary">
          Merge
        </button>
        <div className="ml-4">
          <ImportInventory status={ACTIVE_INVENTORY_LABEL} />
        </div>

        <AddDraftButton
          className={"draft-header-counters__add"}
          disabled={isDisabled}
        />
      </div>
      <MergeModal isOpen={isOpen} onClose={close} />
    </div>
  );
};

export default HeaderComponent;
