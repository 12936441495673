/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import Empty from "../Empty";
import "simplebar/dist/simplebar.min.css";
import CountEntries from "../../../pages/drafts/components/CountEntries";
import Icon from "../Icon";

const ACTIVE_LINK = "waves-effect active mm-active";
const ARROW = "has-arrow";
const CONDENSED = "condensed";
const COUNTERED_ITEMS = {
  shipping: "Shipping",
  taskManagement: "Task management",
};

const AlertContainer = styled.div`
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 32px;
  padding: 8px;
  border: 1px solid #ffa500;
  background-color: rgba(255, 165, 0, 0.2);
  border-radius: 4px;
  display: none;
`;

const GenerateSidebarLinks = (schema, sidebarType) => {
  const location = useLocation();
  const currentLocation = useMemo(() => location.pathname, [location]);
  const counters = useSelector((state) => state.Counters);

  const shippingCountSum =
    counters.shippingCount.picking + counters.shippingCount.shipping;

  const countersMap = {
    [COUNTERED_ITEMS.shipping]: shippingCountSum,
    [COUNTERED_ITEMS.taskManagement]: counters.tasksCount,
  };

  const isCondensed = sidebarType === CONDENSED;

  const [activeLink, setActiveLink] = useState(currentLocation);

  function getLinkClassName(link, arrow = "") {
    if (currentLocation.includes(link)) {
      return `${ACTIVE_LINK} ${arrow}`;
    }
    return `waves-effect ${arrow}`;
  }

  function isOpenedCollapse(link) {
    if (link?.length > activeLink?.length) {
      return;
    }
    return activeLink?.startsWith(link);
  }

  function getLinksFloor(children, parentLink) {
    return children.map((child) => {
      const { icon: Icon = Empty } = child;
      const hasChilds = !!child.children?.length;

      const toggleCollapse = (link) => {
        setActiveLink((prevState) => {
          return prevState === link ? parentLink : link;
        });
      };

      if (child.isTitle) {
        return (
          <li key={child.label} className="menu-title">
            {child.label}
          </li>
        );
      }

      return (
        <li
          key={child.linkTo}
          className={classnames({
            "bottom-menu-item": child.label === "Help" && !isCondensed,
          })}
        >
          <Link
            to={child.linkTo}
            className={
              hasChilds
                ? getLinkClassName(child.linkTo, ARROW)
                : getLinkClassName(child.linkTo)
            }
            onClick={(event) => {
              if (hasChilds) {
                toggleCollapse(child.linkTo);
              } else {
                setActiveLink(child.linkTo);
              }
              event.stopPropagation();
            }}
          >
            <Icon className="mr-2" />
            <span>{child.label}</span>
            {!!countersMap[child.label] && (
              <CountEntries
                count={countersMap[child.label]}
                color="green"
                id="item-counter-wrapper"
              />
            )}
          </Link>

          {hasChilds &&
            (!isCondensed ? (
              <Collapse isOpen={isOpenedCollapse(child.linkTo)}>
                <ul className="sub-menu">
                  {getLinksFloor(child.children, child.linkTo)}
                </ul>
              </Collapse>
            ) : (
              <ul className="sub-menu">
                {getLinksFloor(child.children, child.linkTo)}
              </ul>
            ))}
        </li>
      );
    });
  }

  return (
    <SimpleBar className="h-100">
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled custom-sidebar-menu">
          {getLinksFloor(schema)}
        </ul>
        <AlertContainer className="alert-block">
          <div className="alert-icon">
            <Icon icon="infoIcon" />
          </div>
          <div className="alert-content">
            <strong>Note:</strong>
            <br /> We have temporarily disabled creating eBay listings as we
            resolve an issue.
          </div>
        </AlertContainer>
      </div>
    </SimpleBar>
  );
};

GenerateSidebarLinks.propTypes = {
  schema: PropTypes.array,
};

export default GenerateSidebarLinks;
