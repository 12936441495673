/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { useField } from "formik";
import Bugsnag from "@bugsnag/js";

import ShippingSection from "./partials/ShippingSection";
import ListingUrl from "./partials/ListingUrl";
import PriceDetails from "./partials/PriceDetails";

import FormikSingleSelect from "../../../../base/components/FormikSingleSelect";

import Divider from "../Divider";
import FormUpc from "../DraftBasicFormInner/partials/FormUpc";
import FormCategory, {
  applySuggested,
  useActionsRef,
} from "../DraftBasicFormInner/partials/FormCategory";
import FormSubTitle from "../FormSubTitle";
import FormTitleAndSKU from "../DraftBasicFormInner/partials/FormTitleAndSKU";
import FormPackageDetailsEbay from "../DraftBasicFormInner/partials/FormPackageDetailsEbay";
import OptionalFields from "./partials/OptionalFields";
import ReturnOptions from "./partials/ReturnOptions";

import { ebayListCategoriesToOptions } from "../../helpers/listToOptions";
import {
  getConditionItems,
  getParentCategories,
  getRootCategoriesOption,
} from "../../helpers/fetchCategories";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import StorageService from "../../../../services/StorageService";
import EbayService from "../../../../services/EbayService";

import {
  CALCULATED_SHIPPING_TYPE,
  IS_FREE_SHIPPING,
  pricingFormat,
  productColors,
} from "../../../../base/constants/draft";

import { EbayProductImages } from "../../hooks/useProductImages";
import FormikTextarea from "../../../../base/components/FormikTextarea";
import { getCategoryShortName } from "../../helpers/getCategoryShortName";
import { EBAY_DRAFT_HIDDEN_VALIDATION } from "../../constants";
import { useEbaySession } from "../../../../base/hooks/useEbaySession";
import {
  MAX_CONDITION_DESCRIPTION_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from "../../../../validation/lengthConstants";
import { CONDITIONS_MAP, CONDITIONS_STATUSES } from "../../const/ebay";
import CostOfItem from "../CostOfItem";

const Categories = ({ category, suggested, isLoading }) => {
  const [categoryInitialProps, updateCategoryProps] = useState({
    onInitBreadcrumbs: () => [],
    initialValue: undefined,
  });
  const [loading, { registerPromise }] = useLoading();

  const actions = useActionsRef();
  const [loadingCategories, { registerPromise: awaitCategories }] =
    useLoading();

  /**
   * @type {EbayService}
   */
  const ebayService = useService(EbayService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const getCategoryOptions = useCallback(
    (query, parentId) => {
      return awaitCategories(
        getRootCategoriesOption(query, parentId)
          .then(ebayListCategoriesToOptions)
          .catch((e) =>
            Bugsnag.notify(e, (event) => {
              event.addMetadata("getCategoryOptionsError", {
                query,
                parentId,
              });
            })
          )
      );
    },
    [awaitCategories]
  );

  useEffect(() => {
    // prefetch
    registerPromise(
      awaitCategories(getParentCategories(category))
        .then(updateCategoryProps)
        .catch((e) =>
          Bugsnag.notify(e, (event) => {
            event.addMetadata("getParentCategoriesError", {
              category,
            });
          })
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ebayService, storage, awaitCategories, registerPromise]);

  // Suggested
  useEffect(() => {
    if (category || !suggested) return;

    getRootCategoriesOption(suggested)
      .then((options) => {
        if (!options.length) return;

        applySuggested(actions.current, suggested);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [suggested, getCategoryOptions, category, actions]);

  useEffect(() => {
    isLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <>
      <FormSubTitle title="Category" />
      <Divider />
      <Row>
        <Col md="6">
          <FormCategory
            key={loading.toString()}
            name={"category"}
            getCategoryOptions={getCategoryOptions}
            onUpdatePlaceholder={() => ""}
            loading={loadingCategories}
            {...categoryInitialProps}
            innerRef={actions}
          />
        </Col>
      </Row>
    </>
  );
};

const DraftEbayFormInner = ({
  setFieldValue,
  values,
  postedInfo,
  formRef,
  suggestedFields,
  isPosted = false,
  isLoading = false,
}) => {
  const [session] = useEbaySession();

  const [conditions, updateConditions] = useState([]);

  const [{ value: conditionValue }] = useField({ name: "condition" });

  useEffect(() => {
    if (typeof values.color === "string") {
      const colorId = productColors.find(
        (color) => color.label === values.color
      )?.id;
      if (colorId) {
        setFieldValue("color", colorId);
      } else {
        productColors.push({
          id: values.color,
          label: values.color,
        });
        setFieldValue("color", values.color);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.color]);

  useEffect(() => {
    if (values.productAdditionalFields?.propertyId_Size?.scale_id) {
      setFieldValue(
        "sizeName",
        values.productAdditionalFields?.propertyId_Size?.scale_id
      );
    }
    if (values.productAdditionalFields?.["propertyId_US Shoe Size"]?.scale_id) {
      setFieldValue(
        "sizeName",
        values.productAdditionalFields?.["propertyId_US Shoe Size"]?.scale_id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.productAdditionalFields]);

  useEffect(() => {
    setFieldValue("brandName", values.brand);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.brand]);

  useEffect(() => {
    setFieldValue(
      "categoryShortName",
      getCategoryShortName(values.categoryName)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.categoryName]);

  const [
    { value: isConditionRequired },
    ,
    { setValue: setIsConditionRequired },
  ] = useField({ name: "isConditionRequired" });

  useEffect(() => {
    if (!values.category) {
      setIsConditionRequired(false);
      updateConditions([]);
      return;
    }

    getConditionItems(values.category)
      .then(({ items, required }) => {
        updateConditions(items);
        setIsConditionRequired(required);
      })
      .catch((e) => Bugsnag.notify(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.category, session]);

  useEffect(() => {
    if (
      (!values.buyItNowPrice || values.buyItNowPrice === "") &&
      values.startingPrice !== ""
    ) {
      setFieldValue("buyItNowPrice", values.startingPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.buyItNowPrice, values.startingPrice]);

  useEffect(() => {
    setFieldValue("doesNotApplyUpc", !values?.upc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.upc]);

  const isWeightPackageRequired =
    values.shippingType === CALCULATED_SHIPPING_TYPE &&
    values.freeShipping !== IS_FREE_SHIPPING;

  return (
    <>
      <FormSubTitle title="Listing details" />
      <Divider />
      <FormTitleAndSKU
        titleLabel="Title*"
        titleMaxLength={EBAY_DRAFT_HIDDEN_VALIDATION.title.maxValue}
        isPosted={isPosted}
      />

      <Categories
        category={values.category}
        suggested={suggestedFields.categoryName}
        isLoading={isLoading}
      />

      <Col md="6" className="mb-3">
        <FormUpc values={values} />
      </Col>
      <Row className="mb-4">
        <Col md="6">
          <div className={"mb-2"}>
            <FormikSingleSelect
              name="condition"
              label={"Condition" + (isConditionRequired ? "*" : "")}
              options={conditions}
              containerClassName="mb-0"
              placeholder="Select condition"
              validate={
                isConditionRequired
                  ? (value) => !value && `Condition is required`
                  : undefined
              }
            />
            <span className="font-size-10 text-gray-gomi mb-1">
              A brand-new, unused, unopened, undamaged item (including handmade
              items). See the seller&apos;s listing for full details.
            </span>
          </div>
          {conditionValue !== CONDITIONS_MAP[CONDITIONS_STATUSES.new] &&
            conditionValue && (
              <FormikTextarea
                id="conditionDesc"
                name="conditionDescription"
                label="Condition description"
                placeholder="Enter condition description"
                rows="3"
                isShowLengthDescription
                maxLength={MAX_CONDITION_DESCRIPTION_LENGTH}
                maxLengthDescription={
                  EBAY_DRAFT_HIDDEN_VALIDATION.conditionDescription
                    .conditionDescription
                }
              />
            )}
        </Col>
      </Row>

      <FormSubTitle title="Add photo*" subTitle="Add up to 16 photos" />
      <Divider />
      <EbayProductImages />

      <FormSubTitle title="Item specifics" />
      <Divider />
      <OptionalFields
        categoryId={values.category}
        suggestedFields={suggestedFields}
      />
      <FormSubTitle
        title="Item Description"
        subTitle="Tell buyers about unique features, flaws, or why you are selling it!"
      />
      <Divider />
      <FormikTextarea
        id="desc"
        name="description"
        label="Description*"
        bottomText="Keep in mind how your description will look on a phone."
        placeholder="Enter item description"
        rows="3"
        isShowLengthDescription
        maxLength={MAX_DESCRIPTION_LENGTH}
        maxLengthDescription={EBAY_DRAFT_HIDDEN_VALIDATION.desc.maxValue}
      />

      <FormSubTitle title="Selling Details" />
      <Divider />
      <Row className="mb-3">
        <Col md="6">
          <FormikSingleSelect
            name="pricingFormat"
            label="Pricing format"
            options={pricingFormat}
            defaultValue={pricingFormat[1].id}
            disabled={!!isPosted}
          />
        </Col>
      </Row>
      <PriceDetails
        values={values}
        setFieldValue={setFieldValue}
        isPosted={isPosted}
      />

      <ReturnOptions
        label="Return options"
        values={values}
        setFieldValue={setFieldValue}
      />
      <ShippingSection values={values} />
      <FormPackageDetailsEbay isRequired={isWeightPackageRequired} />
      <CostOfItem />

      <ListingUrl
        values={values}
        isDisabledCopyBtn={formRef?.current?.errors?.link}
        postedInfo={postedInfo}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

DraftEbayFormInner.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  fetchedData: PropTypes.object,
};

export default DraftEbayFormInner;
